import { createI18n } from 'vue-i18n';
import { commonMessages } from './locales';

export type AvailableLocales = 'nl' | 'en';

export const i18n = createI18n<{}, AvailableLocales>({
    legacy: false,
    locale: localStorage.getItem('locale') || 'nl',
    fallbackLocale: 'nl',
    availableLocales: ['nl', 'en'],
    messages: commonMessages, // Global translations available in every 'useI18n' composable
    missingWarn: false,
    fallbackWarn: false,
});
