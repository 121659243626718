import mixpanel from 'mixpanel-browser';
import type { Config, Mixpanel } from 'mixpanel-browser';
import type { App } from 'vue';

interface MixpanelPluginOptions {
    token: string;
    enabled?: boolean;
    config?: Partial<Config>;
}

export default {
    install: (app: App, options: MixpanelPluginOptions) => {
        const enabled = options.enabled || false;

        if (!enabled) return;
        mixpanel.init(options.token, options.config);
        app.provide<Mixpanel>('mixpanel', mixpanel);
    },
};
