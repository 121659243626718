import { en, nl } from 'vuetify/locale';
import { RoleName } from '@/constants/role';

export const commonMessages = {
    en: {
        portfolios: 'Portfolios',
        portfolio: 'Portfolio',
        program: 'Program',
        programs: 'Programs',
        project: 'Project',
        projects: 'Projects',
        department: 'Department',
        departments: 'Departments',
        program_manager: 'Program manager',
        program_managers: 'Program managers',
        subproject: 'Subproject',
        subprojects: 'Subprojects',
        theme: 'Theme',
        themes: 'Themes',
        tag: 'Tag',
        tags: 'Tags',
        approved_idea: 'Approved idea',
        approved_ideas: 'Approved ideas',
        idea: 'Idea',
        ideas: 'Ideas',
        document: 'Document',
        documents: 'Documents',
        download: 'File',
        downloads: 'Files',
        highlight: 'Highlight',
        highlights: 'Highlights',
        preview: 'Preview',
        previews: 'Previews',
        deliverable: 'Deliverable',
        deliverables: 'Deliverables',
        user: 'User',
        users: 'Users',
        finished: 'Finished',
        on_hold: 'On hold',
        running: 'Running',
        delete: 'Delete',
        edit: 'Edit',
        save: 'Save',
        cancel: 'Cancel',
        send: 'Send',
        about: 'About the application',
        actions: 'Actions',
        abbreviation: 'Code',
        name: 'Name',
        description: 'Description',
        start_date: 'Start date',
        end_date: 'End date',
        due_date: 'Due date',
        date: 'Date',
        details: 'Details',
        goal: 'Goal',
        number: 'Number',
        color: 'Color',
        status: 'Status',
        responsible: 'Responsible',
        values: 'Values',
        master_portfolio: 'Source portfolio',
        focus: 'Focus',
        need: 'Need',
        error: {
            invalid_email: 'Invalid email address',
            required_field: 'This field is required',
            unexpected_error: 'An unexpected error occurred',
            required_values: 'Values may not be empty',
            unique_values: 'Values must be unique',
            minimum_characters: 'At least {count} characters',
            maximum_characters:
                'This field may contain a maximum of {max} characters',
            file_size_too_large: 'File must be smaller than {size}',
            file_seleted: 'Select a file',
            impersonate_error:
                'An error occurred while trying to impersonate the user',
            impersonate_cancel_error:
                'An error occurred while trying to cancel impersonation',
        },
        unknown_error: 'An unknown error occurred',
        update_error: 'An error occurred',
        update_success: 'Successfully updated',
        create_error: 'An error occurred',
        create_success: 'Successfully created',
        delete_error: 'An error occurred',
        role: {
            [RoleName.JIGSEYE]: 'JigsEye',
            [RoleName.ADMIN]: 'Admin',
            [RoleName.ORGANIZATION_ADMIN]: 'Organisatie Admin',
            [RoleName.PORTFOLIO_MANAGER]: 'Portfolio Manager',
            [RoleName.PROGRAM_MANAGER]: 'Program Manager',
            [RoleName.PROJECT_MANAGER]: 'Project Manager',
            [RoleName.USER]: 'User',
            [RoleName.TEAM]: 'Team member',
            [RoleName.ESCALATION_LIST]: 'Escalation list',
        },
        $vuetify: en,
    },
    nl: {
        portfolios: "Portfolio's",
        portfolio: 'Portfolio',
        program: 'Programma',
        programs: "Programma's",
        project: 'Project',
        projects: 'Projecten',
        department: 'Afdeling',
        departments: 'Afdelingen',
        program_manager: 'Programma manager',
        program_managers: 'Programma managers',
        project_manager: 'Project manager',
        project_managers: 'Project managers',
        subproject: 'Subproject',
        subprojects: 'Subprojecten',
        theme: 'Thema',
        themes: "Thema's",
        tag: 'Tag',
        tags: 'Tags',
        approved_idea: 'Goedgekeurd idee',
        approved_ideas: 'Goedgekeurde ideeën',
        idea: 'Idee',
        ideas: 'Ideeën',
        document: 'Bestand',
        documents: 'Bestanden',
        download: 'Bestand',
        downloads: 'Bestanden',
        highlight: 'Highlight',
        highlights: 'Highlights',
        preview: 'Preview',
        previews: 'Previews',
        deliverable: 'Deliverable',
        deliverables: 'Deliverables',
        user: 'Gebruiker',
        users: 'Gebruikers',
        finished: 'Afgerond',
        on_hold: 'On hold',
        running: 'Actief',
        delete: 'Verwijderen',
        cancel: 'Annuleer',
        edit: 'Bewerken',
        save: 'Opslaan',
        send: 'Verzenden',
        about: 'Over de applicatie',
        name: 'Naam',
        description: 'Omschrijving',
        actions: 'Acties',
        abbreviation: 'Code',
        start_date: 'Startdatum',
        end_date: 'Einddatum',
        due_date: 'Opleverdatum',
        date: 'Datum',
        details: 'Details',
        goal: 'Doel',
        number: 'Nummer',
        color: 'Kleur',
        status: 'Status',
        responsible: 'Verantwoordelijk',
        values: 'Waarden',
        master_portfolio: 'Bronportfolio',
        focus: 'Focus',
        need: 'Behoefte',
        error: {
            invalid_email: 'Ongeldig e-mailadres',
            required_field: 'Dit veld is verplicht',
            unexpected_error: 'Er is een onverwachte fout opgetreden',
            required_values: 'Waarden mogen niet leeg zijn',
            unique_values: 'Waarden moeten uniek zijn',
            minimum_characters: 'Minstens {count} karakters',
            maximum_characters:
                'Dit veld mag maximaal {max} karakters bevatten',
            file_size_too_large: 'Bestand moet kleiner zijn dan {size}',
            file_seleted: 'Selecteer een bestand',
            impersonate_error:
                'Er is een fout opgetreden bij het impersonaten van de gebruiker',
            impersonate_cancel_error:
                'Er is een fout opgetreden bij het annuleren van het impersonaten',
        },
        unknown_error: 'Er is een onbekende fout opgetreden',
        update_success: 'Succesvol bewerkt',
        update_error: 'Er is een fout opgetreden',
        create_success: 'Succesvol aangemaakt',
        create_error: 'Er is een fout opgetreden',
        delete_error: 'Er is een fout opgetreden',
        role: {
            [RoleName.JIGSEYE]: 'JigsEye',
            [RoleName.ORGANIZATION_ADMIN]: 'Organization Admin',
            [RoleName.ADMIN]: 'Admin',
            [RoleName.PORTFOLIO_MANAGER]: 'Portfolio Manager',
            [RoleName.PROGRAM_MANAGER]: 'Programma Manager',
            [RoleName.PROJECT_MANAGER]: 'Project Manager',
            [RoleName.USER]: 'User',
            [RoleName.TEAM]: 'Teamlid',
            [RoleName.ESCALATION_LIST]: 'Escalatie lijst',
        },
        $vuetify: nl,
    },
};
