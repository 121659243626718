<script setup lang="ts">
import { RouteName } from '@/router';
import { useAuthStore } from '@/stores/auth';
import { computed, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { VCardText } from 'vuetify/components';
import { useIdle } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import AppModal from '@/components/AppModal.vue';

const idleTimeout = 1000 * 60 * 15; // 15 minutes
const logoutTimeout = 1000 * 60; // 60 seconds
const { idle } = useIdle(idleTimeout);

const route = useRoute();

const showModal = computed(
    () => idle.value && route.name !== RouteName['account.login']
);

const { t } = useI18n({
    messages: {
        nl: {
            title: 'Inactiviteitswaarschuwing',
            text: 'Je bent 15 minuten inactief. Om veiligheidsredenen loggen we je automatisch uit.\n            Je wordt over {0} seconden uitgelogd.',
            hint: 'Verplaats de cursor of druk op een willekeurige toets om ingelogd te blijven.',
        },
        en: {
            title: 'Inactivity warning',
            text: "You've been inactive for 15 minutes. For security purposes we will be logging you out automatically.\n            You will be logged out in {0} seconds.",
            hint: 'Just move the cursor or hit any key to stay logged in.',
        },
    },
});

const { logout } = useAuthStore();
const router = useRouter();

const interval = ref<NodeJS.Timeout | string | number | undefined>(undefined);
const counter = ref(0);

watchEffect(() => {
    clearInterval(interval.value);
    counter.value = 0;

    if (showModal.value) {
        interval.value = setInterval(() => (counter.value += 1), 1000);
    }
});

watchEffect(() => {
    if (counter.value > logoutTimeout / 1000) {
        logout();
        router.push({ name: RouteName['account.login'] });
    }
});
</script>

<template>
    <AppModal v-model="showModal" :title="t('title')" width="700" height="240">
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <v-card-text class="pa-8">
            <p>
                {{ t('text', [Math.max(0, logoutTimeout / 1000 - counter)]) }}
            </p>
            <p>
                {{ t('hint') }}
            </p>
        </v-card-text>
    </AppModal>
</template>
