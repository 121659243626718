import type { ThemeDefinition } from 'vuetify';

export default {
    dark: false,
    colors: {
        primary: '#7CAEAD',
        'primary-0': '#000000',
        'primary-10': '#354949',
        'primary-20': '#466261',
        'primary-30': '#5D8382',
        'primary-40': '#7CAEAD',
        'primary-50': '#96BEBD',
        'primary-60': '#B0CECE',
        'primary-70': '#CBDFDE',
        'primary-80': '#D8E7E6',
        'primary-90': '#E5EFEF',
        'primary-95': '#EBF3F3',
        'primary-99': '#F8FBFB',
        'primary-100': '#FFFFFF',
        secondary: '#917670',
        'secondary-0': '#000000',
        'secondary-10': '#3D322F',
        'secondary-20': '#52433F',
        'secondary-30': '#6D5954',
        'secondary-40': '#917670',
        'secondary-50': '#A7918D',
        'secondary-60': '#BDADA9',
        'secondary-70': '#D3C8C6',
        'secondary-80': '#DED6D4',
        'secondary-90': '#E9E4E2',
        'secondary-95': '#EEEAE9',
        'secondary-99': '#F9F8F8',
        'secondary-100': '#FFFFFF',
        tertiary: '#CDB486',
        'tertiary-0': '#000000',
        'tertiary-10': '#574C39',
        'tertiary-20': '#74654C',
        'tertiary-30': '#9A8765',
        'tertiary-40': '#CDB486',
        'tertiary-50': '#D7C39E',
        'tertiary-60': '#E1D2B6',
        'tertiary-70': '#EBE1CF',
        'tertiary-80': '#F0E8DB',
        'tertiary-90': '#F5F0E7',
        'tertiary-95': '#F8F4ED',
        'tertiary-99': '#FCFBF9',
        'tertiary-100': '#FFFFFF',
        error: '#B3261E',
        'error-0': '#000000',
        'error-10': '#410E0B',
        'error-20': '#601410',
        'error-30': '#8C1D18',
        'error-40': '#B3261E',
        'error-50': '#DC362E',
        'error-60': '#E46962',
        'error-70': '#EC928E',
        'error-80': '#F2B8B5',
        'error-90': '#F9DEDC',
        'error-95': '#FCEEEE',
        'error-99': '#FFFBF9',
        'error-100': '#FFFFFF',
        'neutral-0': '#000000',
        'neutral-10': '#181719',
        'neutral-20': '#2F2F31',
        'neutral-30': '#47464A',
        'neutral-40': '#5E5D62',
        'neutral-50': '#7E7D81',
        'neutral-60': '#9E9EA1',
        'neutral-70': '#BFBEC0',
        'neutral-80': '#CFCED0',
        'neutral-90': '#DFDFE0',
        'neutral-95': '#E7E7E7',
        'neutral-99': '#F6F6F6',
        'neutral-100': '#FFFFFF',
        'neutral-variant-0': '#000000',
        'neutral-variant-10': '#181A17',
        'neutral-variant-20': '#30332F',
        'neutral-variant-30': '#484D46',
        'neutral-variant-40': '#60665D',
        'neutral-variant-50': '#80857D',
        'neutral-variant-60': '#A0A39E',
        'neutral-variant-70': '#BFC2BE',
        'neutral-variant-80': '#CFD1CE',
        'neutral-variant-90': '#DFE0DF',
        'neutral-variant-95': '#E7E8E7',
        'neutral-variant-99': '#F6F6F6',
        'neutral-variant-100': '#FFFFFF',
        info: '#7E7D81',
        'info-old': '#2196F3',
        success: '#4CAF50',
        warning: '#FFA500',
        surface: '#F6F6F6',
        'surface-variant': '#466261',
        'on-surface': '#181719',
        'on-surface-variant': '#FFFFFF',
        outline: '#80857D',
        'outline-variant': '#CFD1CE',
    },
} as ThemeDefinition;
