import { createVuetify } from 'vuetify';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import { md3 } from 'vuetify/blueprints';
import * as directives from 'vuetify/directives';
import aliases from './aliases';
import defaults from './defaults';
import jigseyeTheme from './jigseye-theme';
import icons from './icons';
import { useI18n, type I18n } from 'vue-i18n';
import { i18n } from '@/plugins/i18n';

export default createVuetify({
    blueprint: md3,
    directives,
    aliases,
    defaults,
    icons,
    display: {
        thresholds: {
            xs: 600,
            sm: 904,
            md: 1240,
            lg: 1440,
        },
    },
    theme: {
        defaultTheme: 'jigseyeTheme',
        themes: {
            jigseyeTheme,
        },
    },
    locale: {
        /* 
            TODO: Remove type cast
             - See: https://github.com/vuetifyjs/vuetify/issues/14817
        */
        adapter: createVueI18nAdapter({ i18n: i18n as I18n, useI18n }),
    },
});
