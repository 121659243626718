<script setup lang="ts">
import { RouterView } from 'vue-router';
import { VApp } from 'vuetify/components';
import LogoutModal from '@/components/LogoutModal.vue';
import { useDocumentVisibility, useLocalStorage } from '@vueuse/core';
import { watch } from 'vue';
import { TokenService } from './plugins/apollo/token-service';

const visibility = useDocumentVisibility();
const token = useLocalStorage<string | null>('token', null);

// This watcher is needed in addition to the LogoutModal that does not reliably
// trigger when the application is not in focus.
watch(visibility, (newVisibility, oldVisibility) => {
    // Only try to refresh the token when the user refocused on the application.
    if (oldVisibility !== 'hidden' && newVisibility !== 'visible') return;

    // Do not try to refresh token if you are logged out.
    if (!token.value) return;

    // Try to refresh the token if the user continues using the application.
    // User will be logged out if token refresh fails.
    TokenService.getNewToken();
});
</script>

<template>
    <v-app>
        <LogoutModal />
        <RouterView />
    </v-app>
</template>

<style lang="scss">
.v-application {
    background-color: rgb(var(--v-theme-neutral-99));
}
</style>
